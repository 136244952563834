<template>
  <div>
    <h1>{{ $t('views.org.ou_types.ou_types') }}</h1>
    <Loading :data="unit_types">
      <FilterInput v-model="filter"></FilterInput>
      <b-table :filter="filter" responsive striped :items="unit_types" :fields="fields" sort-by="position">
        <template v-slot:head(actions)="">
          <b-button-group class="d-flex">
            <b-button variant="outline-success" @click="createItem()">
              <netvs-icon icon="create"></netvs-icon>
            </b-button>
          </b-button-group>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group class="d-flex">
            <b-button @click="editItem(data.item)" variant="outline-primary"
                      :id="'button-edit-out-' +  data.item.name">
              <netvs-icon icon="edit"></netvs-icon>
            </b-button>
            <b-tooltip :target="'button-edit-out-' +  data.item.name" triggers="hover"
                       variant="primary" placement="bottom">
              {{ $t('system.utils.update_template', { object: $t('views.org.ou_types.type') }) }}
            </b-tooltip>
            <b-button @click="deleteItem(data.item)" variant="outline-danger"
                      :id="'button-delete-out-' +  data.item.name">
              <netvs-icon icon="delete"></netvs-icon>
            </b-button>
            <b-tooltip :target="'button-delete-out-' +  data.item.name" triggers="hover"
                       variant="danger" placement="bottom">
              {{ $t('system.utils.delete_template', { object: $t('views.org.ou_types.type') }) }}
            </b-tooltip>
          </b-button-group>
        </template>
      </b-table>
    </Loading>
    <DBEditor modal_id="out_dbeditor" object_fq_name="org.unit_type" :object_function="object_function"
              :old_data="db_editor_old_data" :presets="db_editor_old_data"
              :non_optionals_order="['name', 'short_name', 'position']"></DBEditor>
  </div>
</template>

<script>
import OrgTypeService from '@/api-services.gen/org.unit_type'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import transactionutil from '@/util/transactionutil'
import Loading from '@/components/Loading'
import FilterInput from '@/components/FilterInput'

export default {
  name: 'OUTypes',
  components: { FilterInput, Loading, DBEditor },
  computed: {
    fields() {
      return [
        {
          label: this.$t('system.name'),
          key: 'name',
          sortable: true
        },
        {
          label: this.$t('views.org.ou_types.short_name'),
          key: 'short_name',
          sortable: true
        },
        {
          label: this.$t('views.org.ou_types.position'),
          key: 'position',
          sortable: true
        },
        {
          label: this.$tc('system.action', 2),
          key: 'actions'
        }
      ]
    }
  },
  data() {
    return {
      filter: '',
      object_function: 'create',
      db_editor_old_data: null,
      unit_types: null,
      db_editor_presets: {}
    }
  },
  methods: {
    deleteItem(item) {
      const ta = transactionutil.generateDeleteElement('org.unit_type', OrgTypeService.deleteParamsList(), item, item.name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    editItem(item) {
      window.console.debug(item)
      this.db_editor_old_data = item
      this.object_function = 'update'
      this.$root.$emit('bv::show::modal', 'out_dbeditor')
    },
    createItem() {
      this.db_editor_old_data = {}
      this.object_function = 'create'
      this.$root.$emit('bv::show::modal', 'out_dbeditor')
    }
  },
  async mounted() {
    this.unit_types = (await OrgTypeService.list(this.$store.state, { sorting_params_list: ['position'] })).data[0]
  }
}
</script>

<style scoped>

</style>
